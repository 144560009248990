/* ==========================================================================
   Fonts
   ========================================================================== */

@font-face {
    font-family: 'VWAGTheSans-Regular';
    src: local('VWAGTheSans-Regular'),
    url('./assets/fonts/VWAGTheSans-Regular.woff2') format('woff2'),
    url('./assets/fonts/VWAGTheSans-Regular.woff') format('woff'),
    url('./assets/fonts/VWAGTheSans-Regular.eot') format('eot'),
    url('./assets/fonts/VWAGTheSans-Regular.svg') format('svg');
}

@font-face {
    font-family: 'VWAGTheSans-Bold';
    src: local('VWAGTheSans-Bold'),
    url('./assets/fonts/VWAGTheSans-Bold.woff2') format('woff2'),
    url('./assets/fonts/VWAGTheSans-Bold.woff') format('woff'),
    url('./assets/fonts/VWAGTheSans-Bold.eot') format('eot'),
    url('./assets/fonts/VWAGTheSans-Bold.svg') format('svg');
    font-weight: bold;
}

@font-face {
    font-family: 'VWAGTheSans-Light';
    src: local('VWAGTheSans-Light'),
    url('./assets/fonts/VWAGTheSans-Light.woff2') format('woff2'),
    url('./assets/fonts/VWAGTheSans-Light.woff') format('woff'),
    url('./assets/fonts/VWAGTheSans-Light.eot') format('eot'),
    url('./assets/fonts/VWAGTheSans-Light.svg') format('svg');
}

@font-face {
    font-family: 'VWAGTheAntiqua-Regular';
    src: local('VWAGTheAntiqua-Regular'),
    url('./assets/fonts/VWAGTheAntiqua-Regular.woff2') format('woff2'),
    url('./assets/fonts/VWAGTheAntiqua-Regular.woff') format('woff'),
    url('./assets/fonts/VWAGTheAntiqua-Regular.eot') format('eot'),
    url('./assets/fonts/VWAGTheAntiqua-Regular.svg') format('svg');
}

@font-face {
    font-family: 'VWAGTheAntiqua-Bold';
    src: local('VWAGTheAntiqua-Bold'),
    url('./assets/fonts/VWAGTheAntiqua-Bold.woff2') format('woff2'),
    url('./assets/fonts/VWAGTheAntiqua-Bold.woff') format('woff'),
    url('./assets/fonts/VWAGTheAntiqua-Bold.eot') format('eot'),
    url('./assets/fonts/VWAGTheAntiqua-Bold.svg') format('svg');
}

/* ==========================================================================
   General
   ========================================================================== */

body, html {
    font-size: 100%;
    font-family: 'VWAGTheSans-Regular';
    display: flex !important;
    justify-content: center !important;
}

h1, h2 {
    font-family: 'VWAGTheSans-Bold';
    letter-spacing: 0.1px
}

h1 {
    font-size: 1.25em;
}

h2 {
    font-size: 1.0em;
}

p {
    font-size: 1.0em;
}

a {
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -ms-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

a.btn {
    font-size: 0.875em;
    letter-spacing: 1.8px;
    line-height: 3.5em;
    background-color: #006384;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'VWAGTheSans-Bold';
    text-align: center;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    -webkit-box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.24);
    -moz-box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.24);
    -ms-box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.24);
    -o-box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.24);
    box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.24);
}

a.btn-secondary {
    font-size: 0.875em;
    letter-spacing: 1.8px;
    line-height: 3.5em;
    background-color: none;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'VWAGTheSans-Bold';
    text-align: center;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    border: 1px solid #fff;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    -webkit-box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.24);
    -moz-box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.24);
    -ms-box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.24);
    -o-box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.24);
    box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.24);
}

a.btn-text {
    font-size: 0.875em;
    letter-spacing: 1.8px;
    line-height: 3.5em;
    width: 100%;
    color: #006384;
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'VWAGTheSans-Bold';
    text-align: center;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
}

a.link {
    color: #006384;
    text-decoration: none;
    display: inline-block;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
}

a.darkLink {
    color: grey;
    text-decoration: none;
    display: inline-block;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
}

a.darkLink:hover {
    color: grey;
    opacity: .6;
}

a.btn:hover {
    background-color: #004666;
}

a.btn-text:hover {
    color: #004666;
}

a.link:hover {
    color: #004666;
}

header {
    z-index: 3;
    position: fixed;
    background-color: #fff;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 96px;
}

td, th {
    border-top: 0.25px solid black;
    border-bottom: 0.25px solid black;
    border-left: 0.25px solid black;
    border-right: 0.25px solid black;
}

th {
    background-color: #c3dee7;
}

table {
    width: 100%;
    border-collapse: collapse;
}

table, th, td {
    border: 1px solid black;
}

th, td {
    padding: 8px;
    text-align: center;
}
th > p > span {
    display: flex;
    align-items: center;
    justify-content: center;
}
td > p > span {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    table {
        transform: scale(1.015);
    }
    th > p > span {
        font-size: 60%;
    }
    td > p > span {
        font-size: 60%;
    }

}

.shadow {
    box-shadow: 0 3px 5px 0 #E9E9E9;
}

footer {
    z-index: 2;
    bottom: 24px;
    left: 0;
    right: 0;
    position: absolute;
    width: 100%;
    height: 48px;
    color: #fff;
    font-size: 0.875em;
    line-height: 3.0em;
    text-align: center;
}

.darkFooter {
    color: grey;
}

footer nav {
    margin-bottom: 0px;
    line-height: 22px;
}

/* ==========================================================================
   Landing Page
   ========================================================================== */

.logo {
    display: block;
    margin: 0 auto;
    width: 288px;
}

.app-icon {
    width: 80px;
    height: 80px;
    background: #fff;
    margin: 0 auto 8px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    border-radius: 16px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.24);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.24);
    -ms-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.24);
    -o-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.24);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.24);
}

.app-icon-small {
    width: 60px;
    height: 60px;
    background: #fff;
    margin: 0 auto 8px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    border-radius: 16px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.24);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.24);
    -ms-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.24);
    -o-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.24);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.24);
}

.app-title {
    height: 75px;
}

.app-title-small {
    width: 60%;
}

img {
    width: 100%;
    height: auto;
}

.card-pf h2 {
    margin: 24px 0 8px;
    text-align: center;
}

.card-pf a.btn {
    width: 70%;
    margin: 8px 15%;
    background-color: #fff;
    color: #004666;
}

.card-pf a.btn-secondary {
    width: 70%;
    margin: 8px 15%;
    background-color: unset;
    color: #fff;
}

.card-pf a.btn:hover {
    font-size: 0.875em;
    background-color: #EDF0F2;
}

footer a.link {
    color: #fff;
}

footer a.link:hover {
    color: #fff;
    opacity: .6;
}

.bg {
    z-index: 1;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url("./assets/images/bg-image-landingpage.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #004666;
}

.content {
    z-index: 2;
    color:#fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    width: 100%;
}

.content::before {
    -webkit-box-flex: 1;
    box-flex: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    content: '';
    display: block;
    height: 128px;
}

.content::after {
    -webkit-box-flex: 1;
    box-flex: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    content: '';
    display: block;
    height: 32px;
}

.card-pf {
    flex-shrink: 0;
    -webkit-flex-shrink: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0 0 48px;
    width: 375px;
}

.block {
    margin-top: 24px;
}

/******************/
/* iPhone Content */
/******************/

.iphone-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100vw;
    max-width: 900px;
    overflow-x: hidden;
}

.iphone-content {
    flex-grow: 1;
    padding-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.iphone-seperator {
    border-left: 1px solid #E9E9E9;
    min-width: 1px;
    height: 400px
}

.content-box {
    min-height: 250px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 330px;
}

.content-box:last-child {
    border: 0px solid #E9E9E9;
}

@media (max-width: 768px) {
    .iphone-content {
        flex-direction: column;
        margin-left: 30px;
        margin-right: 30px;
    }

    .iphone-seperator{
        border-bottom: 1px solid #E9E9E9;

        height: 1px;
        width: 400px
    }

    .content-box {
        width: 100%;

        border-right: 0px solid #E9E9E9 !important;
    }

    .content-box:last-child {
        padding-top: 24px;
    }
}

.content-box > h2 {
    color: #4C5356;
    font-family: "VWAGTheSans-Bold";
    font-size: 16px;
    line-height: 20px;
}

.content-box > p {
    max-width: 500px;
    margin: 0;
    padding: 0 40px;
    color: #4C5356;
    font-family: "VWAGTheSans-Light";
    font-size: 16px;
    line-height: 24px;
    margin-inline-end: 0;
    margin-block-end: 0;
    text-align: center;
}

.content-box > .btn {
    padding: 0 32px;
    margin: 16px;
}

.content-box > .qrcode {
    margin: 20px;
}

.qrcode {
    width: 110px;
    height: 110px
}

.legal-container {
    text-align: left;
    max-width: 960px;
    margin: 120px auto auto;
    padding: 0 16px;
    line-height: 24px;
}

.legal-container h1 {
    margin-top: 24px;
    margin-bottom: 16px;
    color: #004666;
    font-family: "VWAGTheAntiqua-Regular";
    font-size: 24px;
    line-height: 32px;
}

.legal-container h2, h3 {
    font-family: "VWAGTheAntiqua-Bold";
    color: #4c5356;
}

.legal-container h2 {
    font-size: 18px;
    padding: 8px 0;
}

.legal-container h3 {
    font-size: 16px;
}

.legal-container p {
    font-family: 'VWAGTheSans-Light';
    font-size: 16px;
    color: #4c5356;
    padding-bottom: 24px;
}

.legal-container strong {
    font-family: 'VWAGTheSans-Bold';
    color: #4c5356;
    font-size: 16px;
}

.legal-container li {
    font-family: 'VWAGTheSans-Light';
    font-size: 16px;
    color: #4c5356;
}

.legal-container a {
    color: #006384;
    font-family: 'VWAGTheSans-Regular';
    text-decoration-line: none;
}

/* ==========================================================================
   Media Queries
   ========================================================================== */

@media only screen and (max-width: 375px) {

    .card-pf {
        width: 100%;
        max-width: 375px;
    }

    .card-pf h2 {
        margin: 8px 0;
    }

    .volkswagen-logo{
        width: 100%;
    }
}

@media only screen and (max-height: 540px) {

    .iphoneModal .modalContainer {
        display: block;
        height: 100%;
    }

}